<template>
  <v-container
    fluid
    fill-height
    style="display: flex; justify-content: center; align-items: center; min-width: 580px;"
  >
    <v-card
      elevation="1"
      width="60%"
      min-width="550"
      outlined
      style="
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 50px 0px;
      "
    >
      <v-card-title class="text-h4 font-weight-medium">LOG IN</v-card-title>

      <div class="textWrap">
        <v-text-field
          v-model="email"
          label="Email"
          persistent-placeholder
          clearable
          prepend-icon="mdi-email"
          :rules="emailRules"
          :error="emailError"
          @keydown.enter="login"
          @input="resetEmailError"
        ></v-text-field>
      </div>

      <div class="textWrap">
        <v-text-field
          v-model="password"
          label="Password"
          persistent-placeholder
          clearable
          prepend-icon="mdi-lock"
          :type="show1 ? 'text' : 'password'"
          :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="show1 = !show1"
          :rules="passwordRules"
          :error="passwordError"
          @keydown.enter="login"
          @input="resetPasswordError"
        ></v-text-field>
      </div>

      <div
        class="textWrap"
        style="
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 5px;
        "
      >
        <div
          v-show="incorrectPassword"
          style="text-align: center; color: red; font-size: small; line-height:22px"
        >
          Email/Password is incorrect. (Attempt {{ this.loginTry }} of 5 ) <br>
          If you fail to log in more than five times, <br> your account will be locked.
        </div>
        <div
          v-show="loginFail"
          style="text-align: center; color: red; font-size: small; line-height:22px"
        >
          We have detected more than 5 failed login attempts on your account. 
        Your account has been locked and if you would like to unlock it, please contact the WiKi administrator by email.
        </div>
        <div
          v-show="emailError"
          style="text-align: center; color: red; font-size: small; line-height:22px"
        >
          This account does not exist. <br>Please check your credentials and try again.
        </div>
        <div>
          <v-btn
            color="blue-grey lighten-4"
            class="text-none"
            style="margin: 20px 10px"
            @click="login"
            width="150"
            @keydown.enter="login"
            id="login"
          >
            Log in
          </v-btn>
          <v-btn
            color="blue-grey lighten-4"
            class="text-none"
            width="150"
            style="margin: 20px 0px"
            @click="signUp()"
          >
            Sign up
          </v-btn>
        </div>
      </div>
    </v-card>
  </v-container>
</template>

<script>
// import { address } from "@/mixins/api";
import axios from "axios";

export default {
  name: "LoginPage",
  data: () => ({
    error: false,
    emailError: false,
    passwordError: false,
    errorMessage: null,
    show1: false,
    email: null,
    password: null,
    loginFail: false,
    incorrectPassword: false,
    loginTry: 0,
    // check:null,
    userData: {},
    emailRules: [
      (v) => !!v || "Email is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
    passwordRules: [
      (v) => !!v || "Password is required",
      (v) => (v && v.length >= 8) || "Password must be at least 8 characters",
    ],
  }),
  methods: {
    login: function () {
      axios
        .post(`${this.$apiUrl}/user/login`, {
          id: this.email,
          pw: this.password,
        }, {
          withCredentials: true
        })
        .then((res) => {
          console.log(res);
          this.userData.username = res.data.username;
          this.userData.lastLogin = res.data.last_login;

          if (res.data.login === false) {
            if (res.data.error && res.data.error.includes("Your account has been locked")) {
              this.loginFail = true;
              this.incorrectPassword = false;
              this.errorMessage = res.data.error;
              return;
            }
            if (res.data.logintry >= 5) {
              this.incorrectPassword = false;
              this.loginFail = true;
              this.emailError = false;
              this.passwordError = false;
            } else if (res.data.logintry < 5) {
              this.loginTry = res.data.logintry;
              this.incorrectPassword = true;
              this.emailError = false;
              this.passwordError = false;
            } else {
              this.loginFail = false
              this.emailError = true;
              this.passwordError = true;
            }
          } else if (res.data.login) {
            this.$store.commit("setUser", this.userData);
            this.$store.commit("login");
            this.$router.push("/");
          }
          console.log(res.data);
        })
        .catch((error) => {
          console.error(error);
          this.error = true;
          this.emailError = true;
          this.passwordError = true;
        });
    },
    signUp: function () {
      this.$router.push("/signup");
    },
    resetEmailError() {
      this.emailError = false;
      this.error = false;
    },
    resetPasswordError() {
      this.passwordError = false;
      this.error = false;
      
    },
  },
};
</script>

<style scoped>
.textWrap {
  width: 350px;
  min-width: 200px;
}
</style>
